<template lang="html">
	<div class="container-fluid p-5">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header">
						<h6><a href="javascript:void(0)" class="text-info" @click="back()"> <i class="fas fa-arrow-left"></i></a> New Call Now</h6>
					</div>
					<div class="card-body">
						<form @submit.prevent="onSubmit">

							<div class="form-group row">
								<label for="inputType" class="col-sm-2 col-form-label">Type <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<select class="form-control" v-model="dataRequest.type" @change="validationRule()">
										<option value="">Choose Type</option>
										<option value="phone">Phone</option>
										<option value="qr">QR</option>
									</select>
									<small class="text-danger">{{validationData.type}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputPhone" class="col-sm-2 col-form-label">Phone <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="dataRequest.phone" @blur="validationRule()"
										class="form-control" id="inputPhone">
									<small class="text-danger">{{validationData.phone}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputIcon" class="col-sm-2 col-form-label"> Icon </label>
								<div class="col-sm-10">
									<input type="file" class="form-control" id="inputIcon" @change="readIcon">
									<img v-if="dataRequest.icon" v-bind:src="icon" class="banner-image mt-2"/>
									<small class="text-danger">{{validationData.icon}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputQr" class="col-sm-2 col-form-label"> QR </label>
								<div class="col-sm-10">
									<small class="text-danger">You need to choose if the type is QR.</small>
									<input type="file" class="form-control" id="inputQr" @change="readQr">
									<img v-if="dataRequest.qr" v-bind:src="qr" class="banner-image mt-2"/>
									<small class="text-danger">{{validationData.qr}}</small>
								</div>
							</div>

							<button type="submit" class="btn btn-primary float-save">Submit</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="js">
	import {
		mapState,
		mapActions
	} from 'vuex'
	import env from '../../enviorments'
	import Loading from 'vue-loading-overlay'
	import 'vue-loading-overlay/dist/vue-loading.css';
	export default {
		name: 'callNowCreate',
		metaInfo: {
            title: "Setting",
            titleTemplate: "%s ← RJ Dashboard",
        },
		components: {
			Loading
		},
		data() {
			return {
				env,
				isLoading: false,
				fullPage: true,
				icon: "",
				qr: "",
				dataRequest: {
					type: "",
					phone: "",
					icon: "",
					qr: ""
				},
				validationData: {
					type: "",
					phone: ""
				}
			}
		},
		watch: {
			async $route(to, from) {
			},
		},
		computed: {},
		methods: {
			...mapActions({
				callNowStoreAction: 'callNowStoreAction',
			}),
			readIcon(event) {
				this.dataRequest.icon = event.target.files[0];
				//For Show
				const files = event.target.files[0]
				const fileReader = new FileReader()
				fileReader.readAsDataURL(files)
				fileReader.onload = e => {
					this.icon = e.target.result
					this.validationRule()
				};
			},
			readQr(event) {
				this.dataRequest.qr = event.target.files[0];
				//For Show
				const files = event.target.files[0]
				const fileReader = new FileReader()
				fileReader.readAsDataURL(files)
				fileReader.onload = e => {
					this.qr = e.target.result
					this.validationRule()
				};
			},
			async onSubmit() {
				this.isLoading = true
				let validation = this.validationRule()
				if (validation == true) {
					this.isLoading = false
					return
				}
		
				const formData = new FormData();
				formData.append('phone', this.dataRequest.phone); 
				formData.append('type', this.dataRequest.type); 
				formData.append('icon', this.dataRequest.icon); 
				formData.append('qr', this.dataRequest.qr); 

				await this.callNowStoreAction(
					formData
				).then(res => {
					this.back()
					this.isLoading = false;
				}).catch(err => this.isLoading = true)
			},
			back() {
				this.$router.replace({ path: 'call-now-list', query: {page: 1, orderBy: 'desc', sortKey: 'created_at', name: ''} }).catch(()=>{})
			},
			validationRule() {
				let isValidation = []
				if (this.dataRequest.phone == "") {
					this.validationData.phone = "The phone field is required."
					isValidation.push(true)
				} else {
					this.validationData.phone = ""
					isValidation.push(false)
				}
				if (this.dataRequest.type == "") {
					this.validationData.type = "The type field is required."
					isValidation.push(true)
				} else {
					this.validationData.type = ""
					isValidation.push(false)
				}
				
				if (isValidation.includes(true)) {
					return true
				} else {
					return false
				}
			}
		},
		async mounted() {
		}
	}
</script>

<style scoped lang="scss">
	.v-text-field--box .v-input__slot,
	.v-text-field--outline .v-input__slot {
		min-height: 56px;
	}
	.banner-image {
		width: 100px;
		height: 100px;
		object-fit: contain;
	}
</style>